
import Component from 'vue-class-component'

// Register the router hooks with their names
Component.registerHooks([
	'beforeRouteEnter',
	'beforeRouteLeave',
	'beforeRouteUpdate',
]);
import{ DocumentModes, DocumentName, DocumentTypes, DocumentTypesTitles } from './model';
import { Prop, Vue, Watch, Ref } 										  from 'vue-property-decorator';
import Notify 															  from '@/services/helpers/notify';

import { KnowledgeCreatePayload, KnowledgeUpdatePayload } from '@/typings/domain';
import DocumentMetaProjectsKnowledge from './DocumentMetaProjectsKnowledge.vue';
import PreloaderWrap from '@/components/preloader/PreloaderWrap.vue';
import Button from '@/components/ui/button/Button.vue';
import KnowledgeModule from '@/store/modules/knowledge/KnowledgeModule';
import DropdownTitle from '@/components/ui/dropdown-title';
import SelectorBar, { SelectorBarOption } from '@/components/ui/selector-bar';
import DocumentSummary from './DocumentSummary.vue';
import DocumentMetaCategory from './DocumentMetaCategory.vue';
import DocumentMetaAudience from './DocumentMetaAudience.vue';
import Mode from '@/store/modules/mode/mode';
import { CategoriesResponse } from '@/models/dto/responses/categories.response';
import DocumentMetaDirections from './DocumentMetaDirections.vue';
import Editor from '@/components/editor/Editor.vue';
import Icon from '@/components/ui/icon/Icon.vue';

import { ContextMenuItemType, IContextMenuItem } from '@/components/context-menu';
import { BusEvents, ContextMenuBus } from '@/components/context-menu/ContextMenuBus';
import ApiClient from '@/api/api.client';
import ConfirmPopup from '@/components/context-menu/repeatable/ConfirmPopup.vue';
import ClosedPopup from '@/components/context-menu/repeatable/ClosedPopup.vue';

import RolesModule from '@/store/modules/roles/RolesModule';
import CategoriesModule from '@/store/modules/categories/CategoriesModule';
import SubCategoriesModule from '@/store/modules/subcategories/SubCategoriesModule';
import { SubCategories } from '@/models/dto/responses/subcategories.response';
import TagsModule from '@/store/modules/tags/TagsModule';
import { Tag } from '@/typings/domain';
import UsersModule from '@/store/modules/users/UsersModule';
import ProjectsModule from '@/store/modules/projects/ProjectsModule';

import ROUTES from '@/router/ROUTES';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import { getCookie, rmCookie, setCookie } from '@/composables/cookie';
import { eventBus } from '@/composables/event-bus';



@Component({
	components: {
		Editor,
		Icon,
		DocumentSummary,
		DocumentMetaCategory,
		ConfirmPopup,
		DocumentMetaAudience,
		DocumentMetaDirections,
		DocumentMetaProjectsKnowledge,
		DropdownTitle,
		PreloaderWrap,
		Button,
		SelectorBar,
		ClosedPopup,
	},
})
export default class DocumentComponent extends Vue {
	public projectKnowledgePageLoaded = false;
	public confirmContextVisible      = false;
	public currentSubCategories       = Array<SubCategories>();
	public showedOnceLeavePopup       = false;
	public documentNameUpdated        = false;
	public categoriesLoaded           = false;
	public currentCategeory           = '';
	public documentContent            : object = null;
	public renderComponent            = true;
	public editorContent              : any    = null;
	public knowlageTitle              = '';
	public keyForUpdate               = 1;
	public preventPath                = '';
	public oldSubtitle                = '';
	public currentTags                = Array<Tag>();
	public tagsLoaded                 = false;
	public unActived                  = false;
	public isForAll                   = false;
	public loading                    = true;
	public isPopup                    = true;
	public modes                      = DocumentModes;
	// public pageMutated = false;
	public documentName: DocumentName = {
		title   : '',
		subtitle: '',
	};
	public documentLoaded = false;
	@Prop({
		default: DocumentModes.View,
	})
	mode: DocumentModes;
	type: DocumentTypes = DocumentTypes.Post;
	@Ref('myMainButton') myMainButton!: HTMLDivElement;
	@Ref('myElement') myElement!      : HTMLDivElement;
	@Ref('myButton') myButton!        : HTMLDivElement;
	@Ref('editor') editor!            : HTMLDivElement;

	public selectedSubCategory: number   = null;
	public selectedSpecialist : number[] = [];
	public selectedCategory : number     = null;
	public currentSection : any          = 'knowledge';
	public knowledgeLink      			 = '';
	public editorMutated      			 = false;
	public subCategoryId : number   	 = null;
	public selectedTags : number[] 		 = [];
	public tabsMutated        			 = false;
	public mutableMode : DocumentModes   = null;
	public categoryId : number   		 = null;
	public parserHtml : string   		 = null;
	public isGeneral 					 = false;
	public savePage           			 = false;
	public doc : any      				 = [];
	public path               			 = this.$route.path
	public get typesTitles(): DocumentTypesTitles {
		return {
			[DocumentTypes.Post]: this.$t('Documents.Document.TypeKnowledge') as string,
			[DocumentTypes.Link]: this.$t('Documents.Document.TypeLink') as string,
		};
	}

	public get currentMode() {
		return this.mutableMode === this.modes.View ? true : false;
	}
	public get subcategoryName() {
		return this.currentSubCategories.length ? this.currentSubCategories[0].name : '';
	}
	public get tags() {
		return TagsModule.tags
	}
	get project() {
		return ProjectsModule.clientProject;
	}
	beforeRouteLeave(to: any, from: any, next: any) {
		if (this.pageMutated) {
			this.preventPath = to.path;
			this.confirmContextVisible = true;
			if (this.confirmContextVisible && !this.showedOnceLeavePopup) {
				this.showedOnceLeavePopup = true;
				return;
			}
		}
		next()
	}

	closePopup() {
		rmCookie('unActived')
		this.isPopup = false
	}


	updateTitle(newTitle: string) {
		this.knowlageTitle = newTitle;
	}

	setTimer() {
		const doc = document.querySelector('.pulse-document')
		let time: any = setTimeout(() => this.unActived = true, 10800000);

		doc.addEventListener('mousemove', () => {
			clearTimeout(time)
			time = setTimeout(() => this.unActived = true, 10800000);
		})
		doc.addEventListener('keypress', () => {
			clearTimeout(time)
			time = setTimeout(() => this.unActived = true, 10800000);
		})
	}

	get isUnactivated() { return getCookie('unActived') ? true : false }

	get pageMutated() {
		if (!this.savePage) {
			if (this.$route.path.indexOf('/documents/create') > -1) {
				if ((this.documentName.subtitle || this.documentName.title) || this.editorMutated) {
					return true;
				}
				else
					return false
			}
			if (this.$route.hash.indexOf('edit') === 1) {
				if (this.documentNameUpdated || this.editorMutated) {
					return true;
				}
				else {
					return false;
				}
			}
		}
		return false;
		// return !this.savePage && this.$route.path.indexOf('/documents/create') > -1 || this.$route.hash.indexOf('edit') === 1
	}
	preventRedirect() {
		this.showedOnceLeavePopup = false;
		this.confirmContextVisible = false;
	}
	returnPage() { window.history.go(-1) }
	updateContent(content: object) {
		this.editorMutated = true;
	}
	async continuePath() {
		this.confirmContextVisible = false
		await this.$nextTick();
		this.$router.push(this.preventPath)
	}
	public get parsedHtml() {
		return this.parserHtml;
	}
	public get items(): Array<IContextMenuItem> {
		return [
			{
				type: ContextMenuItemType.link,
				text: this.typesTitles[DocumentTypes.Post],
				action: (node) => {
					this.setType(DocumentTypes.Post);
					ContextMenuBus.$emit(BusEvents.destroyNode, node);
				},
			},
			{ type: ContextMenuItemType.divider },
			{
				type: ContextMenuItemType.link,
				text: this.typesTitles[DocumentTypes.Link],
				action: (node) => {
					this.setType(DocumentTypes.Link);
					ContextMenuBus.$emit(BusEvents.destroyNode, node);
				},
			},
		];
	}

	public get selectorBarOptions(): Array<SelectorBarOption> {
		return [
			{ title: this.typesTitles[DocumentTypes.Post], value: DocumentTypes.Post },
			{ title: this.typesTitles[DocumentTypes.Link], value: DocumentTypes.Link },
		];
	}
	setType(value: DocumentTypes) {
		this.type = value;
	}
	selectedSpecialisations(arr: number[]) {
		this.selectedSpecialist = arr;
	}
	selectedMetaDirections(arr: number[]) {
		if (this.currentSection === 'projectKnowledge') {
			const tagId = arr.filter((el) => el != -111)
			if (arr.indexOf(-111) > -1) {
				this.isGeneral = true;
			}
			this.selectedTags = tagId;
		}
		else {
			this.selectedTags = arr;
		}
	}
	categoryLoaded(val: boolean) {
		this.categoriesLoaded = val;
	}
	linkValid(to: string): boolean {
		const external = /^\w+:\/\//.test(to);
		return external
	}
	tagLoaded() {
		this.tagsLoaded = true;
	}
	get user() {
		return UsersModule.currentUser;
	}

	get companyType() {
		if (this.user.general_settings.company_type === 'service' && this.user.general_settings.services_count === 'many') {
			return 'KnowledgeWithDirection'
		}
		if (this.user.general_settings.company_type === 'service' && this.user.general_settings.services_count === 'one') {
			return 'KnowledgeWithProjectName'
		}
		return ''
	}
	async mounted(): Promise<void> {
		eventBus.on('setSwiperList',  payload => KnowledgeModule.setSlideList(payload))

		this.knowlageTitle = getCookie('documentTitle') || 'Без названия';
		this.setTimer()
		this.isAllow()
		if (this.isAllow()) {
			TagsModule.fetchTags();
			if (this.$route.params.projectId) {
				this.currentSection = 'projectKnowledge'
			}
			this.mutableMode = JSON.parse(JSON.stringify(this.mode));
			Mode.toggleMode(this.mutableMode);
			if (this.$route.hash.indexOf('edit') === 1) {
				this.mutableMode = 'edit' as DocumentModes
			}
			if (this.mutableMode === DocumentModes.Edit || this.mutableMode === DocumentModes.View) {
				await this.getDocument();
			}
			else {
				this.documentLoaded = true;
			}
		}
		else {
			this.$router.push('/')
		}
		if (this.$route.path.indexOf('/documents/create') > -1 || this.$route.hash.indexOf('edit') === 1) {
			window.addEventListener('beforeunload', this.handleRedirect);
		}
		this.setScroll();
		window.addEventListener('scroll', this.handleScroll);
		this.handleScroll();
	}

	setScroll() {
		setTimeout(() => {
			window.scrollBy(0, 1);
		}, 0);
	}
	handleScroll() {
		const windowHeight = window.innerHeight || document.documentElement.clientHeight;
		const toolbar: HTMLElement = document.querySelector('.editor__toolbar-wrapper')
		const outerSpace   : HTMLElement = document.querySelector('.pulse-document__summary')
		const mainButton   = this.myMainButton;
		const element      = this.myElement;
		const button       = this.myButton;

		if (element.getBoundingClientRect().top <= -17) {
			toolbar.classList.add('fixed')
			button.classList.add('fixed');
			toolbar.style.left = `${window.innerWidth - (outerSpace.getBoundingClientRect().x + outerSpace.offsetWidth) + 88}px`
			button.style.right = `${window.innerWidth - (outerSpace.getBoundingClientRect().x + outerSpace.offsetWidth) + 88}px`
		}
		else {
			button.classList.remove('fixed');
			button.style.right = '';

			toolbar.classList.remove('fixed');
			toolbar.style.left = '';
		}

		if (mainButton.getBoundingClientRect().top < windowHeight && mainButton.getBoundingClientRect().bottom >= 0)
			button.classList.remove('block')
		else
			button.classList.add('block')
	}

	handleRedirect(event: any) {
		event.preventDefault();
		event.returnValue = '';
		return '';
	}
	beforeDestroy() {

		this.confirmContextVisible = true;
		window.removeEventListener('beforeunload', this.handleRedirect)
		window.removeEventListener('scroll', this.handleScroll);
		RolesModule.setRolesByLoad([]);
		this.mutableMode == 'edit' ?
			ApiClient.knowledge.cancelEditing(Number(this.path.split('/')[this.path.split('/').length - 1])) :
			null
	}
	selectedMetaCategory(val: any) {
		if (this.categoryId && this.categoryId != val[0]) {
			this.tabsMutated = true;
		}
		this.selectedCategory = val[0];
		this.selectedSubCategory = val[1];

	}
	isAllow() {
		if (this.$route.path.indexOf('/documents/create') > -1 || (this.$route.params && this.$route.params.documentId && this.$route.hash === '#edit')) {
			if (!UsersModule.currentUser.permitions.knowledge.manage && !UsersModule.currentUser.admin) {
				Notify.error('Недостаточно прав')
				return false;
			}
		}
		return true;
	}
	setProjectIds(projectIds: Array<number>) {
		if (this.currentSection === 'projectKnowledge') {
			const tagId = projectIds.filter((el) => el != -111)
			if (projectIds.indexOf(-111) > -1) {
				this.isGeneral = true;
			}
			this.projectsIds = tagId;
		}
	}

	public projectsIds = Array<number>()
	private async getDocument(): Promise<void> {
		try {
			await ApiClient.knowledge
				.getKnowledge(this.$route.params.documentId)
				.then((result: any) => {
					this.isForAll = result.for_all;
					this.categoryId = result.category_id;
					this.documentContent = JSON.parse(result.content);
					(this.documentName.title = result.title), (this.oldSubtitle = result.description);
					this.documentName.subtitle = result.description ? result.description : '';
					this.selectedTags = result.tags.map((el: any) => el.id);

					if (this.linkValid(result.link)) {
						this.type = DocumentTypes.Link;
						this.knowledgeLink = result.link;
					}
					if (result.subcategory_id) {
						this.subCategoryId = result.subcategory_id;
						this.selectedSubCategory = result.subcategory_id;

						if (this.mutableMode === 'view') {
							SubCategoriesModule.fetchSubCategoriesById(result.subcategory_id).finally(() => {
								const resp = SubCategoriesModule.subcategories as Array<SubCategories>;
								this.currentSubCategories = resp;
							});
						}
					}
					if (this.mutableMode === 'view') {
						CategoriesModule.fetchCategory(this.categoryId.toString()).finally(() => {
							const resp = CategoriesModule.category as CategoriesResponse;
							this.currentCategeory = resp.name;
						});
						const ids = result.tags.map((el: Tag) => {
							return el.id;
						});
						this.currentTags = TagsModule.tags.filter((el) => ids.indexOf(el.id) >= 0);
					}
					// eslint-disable-next-line
					// @ts-ignore
					RolesModule.setRolesByLoad(result.specialisations.map((el: object) => el.id));

					if (result.is_new) ApiClient.knowledge.setAsViewed(result.id);
				})
				.finally(() => {
					this.documentLoaded = true;
				});
		} catch (e) {
			console.error(e);
		}
	}

	@Watch('unActived')
		closeDoc(isInactive: boolean) {
		if (['edit', 'create'].includes(this.mutableMode)) {
			setCookie('unActived', true);
			this.saveDocument();
		}
	}
	@Watch('mutableMode')
	resetDataComponent(val: string) {
		Mode.toggleMode(val);
		if (val == 'create') {
			this.keyForUpdate++;
		}
	}

	get shortTags() {
		if (this.currentTags.length > 3)
			return this.currentTags.slice(0, 3);
		else
			return this.currentTags;
	}

	public async saveDocument(): Promise<void> {
		if (this.documentName.title.length > 100) {
			Notify.error('Максимальное количество допустимых симоволов: 100')
		}
		if (!this.documentName.title) {
			Notify.error(this.$t('Documents.Document.DocNameEmptyMsg') as string);
			return;
		}
		if (this.$route.params.documentId) {
			if (this.selectedSubCategory === 9999) {
				this.selectedSubCategory = this.subCategoryId;
			}
			this.updateKnowledge();
		}
		else {
			this.saveKnowledge()
		}
		// eslint-disable-next-line
		// @ts-ignore
		this.$refs.documentHeader.scrollIntoView(true);
	}
	public async startEdit() {
		// eslint-disable-next-line
		// @ts-ignore
		const path     = this.$route.path.split('/')
		const editUser = await ApiClient.knowledge.getEditInfo(Number(path[path.length - 1]))
		editUser.name ?
			Notify.error(`<div>В данный момент знание редактирует <a href="${MAIN_API_HOST.replace('/api', '')}/team/user/${editUser.id}">${editUser.name}</a>, свяжитесь с пользователем или попробуйте позже</div>`) :
			this.$router.push(`/documents/${this.$route.params.documentId}#edit`)
	}
	async updateKnowledge() {
		if (!this.knowledgeLink) {
			this.knowledgeLink = String('empty');
		}
		this.savePage = true;
		const payload: KnowledgeUpdatePayload = {
			specialisations: this.selectedSpecialist.includes(-999) ? 'all' : this.selectedSpecialist,
			subcategory_id : this.subCategoryId || this.selectedSubCategory,
			category_id    : this.selectedCategory,
			for_all        : this.selectedSpecialist.includes(-999) ? true: false,
			title          : this.documentName.title,
			tags           : this.selectedTags,
			link           : this.knowledgeLink,
			id             : this.$route.params.documentId,
		};
		// eslint-disable-next-line
		// @ts-ignore
		if (this.type != 'link') {
			// eslint-disable-next-line
			// @ts-ignore
			this.editorContent = this.$refs.editor.saveMy();
			const hasSlider    = this.editorContent.content.find((el: any) => el.attrs?.content);

			if (hasSlider)
				KnowledgeModule.SlideList.forEach((el: any, index: number) => this.editorContent.content[index].attrs.content = el.slideList.slidrSlides)

			payload.content = JSON.stringify(this.editorContent);
		}
		else
			delete payload.content
		if (this.documentName.subtitle || (!this.documentName.subtitle && this.oldSubtitle))
			payload.description = this.documentName.subtitle;
		await ApiClient.knowledge.updateKnowledge(payload);
		Notify.success(this.$t('Знание изменено') as string);
		window.history.go(-1)
	}
	async saveKnowledge() {
		if (this.selectedCategory === 0) {
			Notify.error('Выберите категорию')
			return;
		}
		const payload: KnowledgeCreatePayload = {
			specialisations: this.selectedSpecialist.includes(-999) ? 'all' : this.selectedSpecialist,
			subcategory_id : this.selectedSubCategory,
			category_id    : this.selectedCategory,
			title          : this.documentName.title,
			tags           : this.selectedTags,
			link           : this.knowledgeLink,
		};
		if (this.companyType.length && this.currentSection === 'projectKnowledge') {
			payload.client_id = this.project.client_id
			payload.projects = this.projectsIds
		}
		if (!this.knowledgeLink) {
			delete payload.link;
		}
		// eslint-disable-next-line
		// @ts-ignore
		if (this.type != 'link') {
			// eslint-disable-next-line
			// @ts-ignore
			this.editorContent = this.$refs.editor.saveMy();
			payload.content = JSON.stringify(this.editorContent);
		}
		if (this.isGeneral) {
			payload.is_general = true;
		}
		if (this.documentName.subtitle) {
			payload.description = this.documentName.subtitle;
		}
		this.savePage = true;
		const response = await ApiClient.knowledge.createKnowledge(payload);
		Notify.success(this.$t('Documents.Document.DocSaveSuccessMsg') as string);
		window.history.go(-1)
	}
	public forceRerender() {
		// Remove MyComponent from the DOM
		this.renderComponent = false;

		// Wait for the change to get flushed to the DOM
		setTimeout(() => {
			this.renderComponent = true;
		}, 100);
	}
}
